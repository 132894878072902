import React from 'react';

const SvgIndeterminateCheckbox = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.25 13.5H4.5v-3h15.75v3z"
    />
  </svg>
);

export default SvgIndeterminateCheckbox;
