import React from 'react';
import PropTypes from 'prop-types';
import './FlightTableLoading.scss';
import Grid from '../../utils/grid';

const FlightTableLoading = ({ rowNumber, colNumber }) => {
  const grid = new Grid(parseInt(rowNumber, 10), colNumber);
  const mainClass = 'flight-table-loading';

  let rowKey = 0;
  let cellKey = 0;
  return (
    grid.content.map((row) => {
      rowKey += 1;
      return (
        <tr className={mainClass} key={`flight-table-loading${rowKey}`}>
          {
            row.map(() => {
              cellKey += 1;
              return (
                <td className={`${mainClass}__cell`} key={`loading-cell${cellKey}`}>
                  <div className={`${mainClass}__cell__bar`} />
                </td>
              );
            })
          }
        </tr>
      );
    })
  );
};

FlightTableLoading.propTypes = {
  rowNumber: PropTypes.number,
  colNumber: PropTypes.number.isRequired,
};

FlightTableLoading.defaultProps = {
  rowNumber: 3,
};

export default FlightTableLoading;
