import React from 'react';

const SvgTarget = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 12a8 8 0 1016 0 8 8 0 00-16 0zm8-10C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 12a4 4 0 108 0 4 4 0 00-8 0zm4-6a6 6 0 100 12 6 6 0 000-12z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 10a2 2 0 100 4 2 2 0 000-4z"
    />
  </svg>
);

export default SvgTarget;
