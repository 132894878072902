import React from 'react';

const SvgBlocked = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#blocked_svg__clip0)">
      <path d="M4.929 4.929c-3.89 3.889-3.89 10.253 0 14.142 3.889 3.89 10.253 3.89 14.142 0 3.89-3.889 3.89-10.253 0-14.142-3.889-3.89-10.253-3.89-14.142 0zm12.02 3.535L8.465 16.95 7.05 15.536l8.486-8.486 1.414 1.414z" />
    </g>
    <defs>
      <clipPath id="blocked_svg__clip0">
        <path d="M0 0h24v24H0V0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgBlocked;
