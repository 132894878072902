import React from 'react';

const SvgCounter = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path d="M22 4h-7a2 2 0 00-2 2v12a2 2 0 002 2h7a2 2 0 002-2V6a2 2 0 00-2-2zm-2 13h-2V9h-2V7h4v10zM6 8.75H5a1 1 0 00-1 1v4.5a1 1 0 001 1h1a1 1 0 001-1v-4.5a1 1 0 00-1-1z" />
    <path d="M9 4H2a2 2 0 00-2 2v12a2 2 0 002 2h7a2 2 0 002-2V6a2 2 0 00-2-2zm0 10.25a3 3 0 01-3 3H5a3 3 0 01-3-3v-4.5a3 3 0 013-3h1a3 3 0 013 3v4.5z" />
  </svg>
);

export default SvgCounter;
