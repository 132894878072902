import React from 'react';

const SvgMathDivide = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 11v2h16v-2H4zm8 8a2 2 0 100-4 2 2 0 000 4zm0-10a2 2 0 100-4 2 2 0 000 4z"
    />
  </svg>
);

export default SvgMathDivide;
