import React from 'react';

const SvgTemplate = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path d="M8 7h8v2H8V7z" />
    <path d="M11 17V8h2v9h-2zM20 2h2v2h-2V2zM2 2h2v2H2V2zM20 7h2v2h-2V7zM2 7h2v2H2V7zM7 2h2v2H7V2zM7 20h2v2H7v-2zM11 2h2v2h-2V2zM11 20h2v2h-2v-2zM15 2h2v2h-2V2zM15 20h2v2h-2v-2zM20 11h2v2h-2v-2zM2 11h2v2H2v-2zM20 15h2v2h-2v-2zM2 15h2v2H2v-2zM20 20h2v2h-2v-2zM2 20h2v2H2v-2z" />
  </svg>
);

export default SvgTemplate;
