import React from 'react';

const SvgCheckboxCheck = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.722 7.81L9.162 19.372 3.6 13.811l2.12-2.121 3.44 3.439 9.44-9.44 2.12 2.122z"
    />
  </svg>
);

export default SvgCheckboxCheck;
